import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
  Divider,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
    maxWidth: 400,
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function FiltersList({ handleSwitch, filters }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState({
    anchor: null,
    description: null,
  });

  const handlePopoverOpen = (e, description) => {
    setAnchorEl({ anchor: e.currentTarget, description });
  };

  const handlePopoverClose = () => {
    setAnchorEl({ anchor: null, description: null });
  };

  const open = Boolean(anchorEl?.anchor);

  return (
    <div className="clients__filters_wrapper">
      <List style={{ padding: 0 }}>
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl?.anchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          {anchorEl?.description}
        </Popover>

        {/* CARTERA */}

        <ListItem role="TITLE">
          <ListItemText primary="Cartera" />
        </ListItem>

        <Divider component="li" />

        <ListItem d="De Gevico" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["CLI_GEVICO"]}
              onChange={handleSwitch}
              name="CLI_GEVICO"
            />
          </ListItemIcon>
          <ListItemText secondary="De Gevico" />
        </ListItem>

        <ListItem d="Fuera de Gevico" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["outsideGevico"]}
              onChange={handleSwitch}
              name="outsideGevico"
            />
          </ListItemIcon>
          <ListItemText secondary="Fuera de Gevico" />
        </ListItem>

        <ListItem d="Cuasiclientes" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["MICRO::Cuaisclientes"]}
              onChange={handleSwitch}
              name="MICRO::Cuaisclientes"
            />
          </ListItemIcon>
          <ListItemText secondary="Cuasiclientes" />
        </ListItem>

        <ListItem d="Visitado > 1 año" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["lastVisit"]}
              onChange={handleSwitch}
              name="lastVisit"
            />
          </ListItemIcon>
          <ListItemText secondary="Visitado > 1 año" />
        </ListItem>

        {/* CAMPAÑAS */}

        <ListItem role="TITLE">
          <ListItemText primary="Campañas" />
        </ListItem>

        <Divider component="li" />

        <ListItem d="Captar Alta FD [N]" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["TESA::CAPTAR FD Alta FD"]}
              onChange={handleSwitch}
              name="TESA::CAPTAR FD Alta FD"
            />
          </ListItemIcon>
          <ListItemText secondary="Captar Alta FD [N]" />
        </ListItem>

        <ListItem d="Ofrecer Segunda Sede [N]" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["TESA::CAPTAR FD Segundas Sedes FD"]}
              onChange={handleSwitch}
              name="TESA::CAPTAR FD Segundas Sedes FD"
            />
          </ListItemIcon>
          <ListItemText
            secondary="Ofrecer Segunda Sede [N]"
            onMouseEnter={(e) =>
              handlePopoverOpen(
                e,
                "Prioritario dar una segunda sede para el Fusión Digital"
              )
            }
            onMouseLeave={handlePopoverClose}
          />
        </ListItem>

        <ListItem d="Fondos Europeos [N]" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["fondosEuropeos"]}
              onChange={handleSwitch}
              name="fondosEuropeos"
            />
          </ListItemIcon>
          <ListItemText
            secondary="Fondos Europeos [N]"
            onMouseEnter={(e) =>
              handlePopoverOpen(
                e,
                "Clientes aptos para kit Fondos Europeos en Fases 1, 2 o 3"
              )
            }
            onMouseLeave={handlePopoverClose}
          />
        </ListItem>

        <ListItem d="Novar FD Reblind. [N]" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["TESA::NOVAR FD Reblindaje"]}
              onChange={handleSwitch}
              name="TESA::NOVAR FD Reblindaje"
            />
          </ListItemIcon>
          <ListItemText secondary="Novar FD Reblind. [N]" />
        </ListItem>

        <ListItem d="Renovar TMA [N]" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["TESA::EQUIPAMIENTO Renovación TMA"]}
              onChange={handleSwitch}
              name="TESA::EQUIPAMIENTO Renovación TMA"
            />
          </ListItemIcon>
          <ListItemText secondary="Renovar TMA [N]" />
        </ListItem>

        <ListItem d="Rent 2 Rent [N]" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["TESA::EQUIPAMIENTO Propensión a contratar R2R"]}
              onChange={handleSwitch}
              name="TESA::EQUIPAMIENTO Renovación TMA"
            />
          </ListItemIcon>
          <ListItemText
            secondary="Rent 2 Rent [N]"
            onMouseEnter={(e) =>
              handlePopoverOpen(e, "Cliente propenso a contratar R2R")
            }
            onMouseLeave={handlePopoverClose}
          />
        </ListItem>

        <ListItem d="Captar No cliente [N]" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["TESA::CAPTAR No Clientes"]}
              onChange={handleSwitch}
              name="TESA::CAPTAR No Clientes"
            />
          </ListItemIcon>
          <ListItemText
            secondary="Captar No cliente [N]"
            onMouseEnter={(e) =>
              handlePopoverOpen(
                e,
                "Solo captación, prioritario traer el cliente a Telefónica"
              )
            }
            onMouseLeave={handlePopoverClose}
          />
        </ListItem>

        {/* INDICADORES */}

        <ListItem role="TITLE">
          <ListItemText primary="Indicadores" />
        </ListItem>

        <Divider component="li" />

        <ListItem d="Subvenciones" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["MICRO::Subvenciones"]}
              onChange={handleSwitch}
              name="MICRO::Subvenciones"
            />
          </ListItemIcon>
          <ListItemText
            secondary="Subvenciones"
            onMouseEnter={(e) =>
              handlePopoverOpen(e, "Han recibido una subvención pública")
            }
            onMouseLeave={handlePopoverClose}
          />
        </ListItem>

        <ListItem d="Portabilidades" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["portasNumero"]}
              onChange={handleSwitch}
              name="portasNumero"
            />
          </ListItemIcon>
          <ListItemText
            secondary="Portabilidades"
            onMouseEnter={(e) =>
              handlePopoverOpen(e, "Se han portado una o más líneas")
            }
            onMouseLeave={handlePopoverClose}
          />
        </ListItem>

        <ListItem d="Tienen FD Básico" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["SCRIPT::Fusión Empresas Básico (S/N)"]}
              onChange={handleSwitch}
              name="SCRIPT::Fusión Empresas Básico (S/N)"
            />
          </ListItemIcon>
          <ListItemText
            secondary="Tienen FD Básico"
            onMouseEnter={(e) =>
              handlePopoverOpen(e, "Tienen el antiguo Fusión Empresas Básico")
            }
            onMouseLeave={handlePopoverClose}
          />
        </ListItem>

        <ListItem d="No Tienen FD Cloud" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["noCloud"]}
              onChange={handleSwitch}
              name="noCloud"
            />
          </ListItemIcon>
          <ListItemText
            secondary="No Tienen FD Cloud"
            onMouseEnter={(e) =>
              handlePopoverOpen(e, "No tienen el antiguo Fusión Empresas Cloud")
            }
            onMouseLeave={handlePopoverClose}
          />
        </ListItem>

        <ListItem d="IP Estática" className="clients__list-item">
          <ListItemIcon>
            <Switch
              color="primary"
              checked={filters["SCRIPT::IP Estática (S/N)"]}
              onChange={handleSwitch}
              name="SCRIPT::IP Estática (S/N)"
            />
          </ListItemIcon>
          <ListItemText
            secondary="IP Estática"
            onMouseEnter={(e) =>
              handlePopoverOpen(e, "Tienen contratado al menos una IP estática")
            }
            onMouseLeave={handlePopoverClose}
          />
        </ListItem>
      </List>
    </div>
  );
}

export default FiltersList;
